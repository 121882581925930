import BookNowBtn from 'components/Buttons/BookNowBtn';
import { RegionMngt } from 'hooks/RegionMngt.hook';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Region, useToursStore } from 'store/tourStore';

export const Tours: React.FC = (): ReactElement => {
  const { regions } = useToursStore();
  const { t } = useTranslation();
  const { setSelectedRegion, handleRegionClick } = RegionMngt();

  const handleClickOnRegion = (region: Region) => {
    setSelectedRegion(region);
    handleRegionClick(region);
  };

  return (
    <div
      style={{
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        alignItems: 'center',
        gap: '2rem',
      }}
    >
      <div>
        <h2
          style={{
            fontSize: '3.5rem',
            textAlign: 'center',
            fontFamily: 'Lexend',
            fontWeight: '700',
          }}
        >
          {t('menu.ourTours').toUpperCase()}
        </h2>
        <h3
          style={{
            fontFamily: 'frank-ruhl-libre',
            fontSize: '1.5rem',
            fontWeight: '600',
          }}
        >
          {t('ourToursDescription').toUpperCase()}
        </h3>
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
          gap: '1.5rem',
          width: '70%',
        }}
      >
        {regions.map((region, idx) => (
          <div
            key={idx}
            style={{
              position: 'relative',
              textAlign: 'center',
              cursor: 'pointer',
              overflow: 'hidden',
            }}
            onClick={() => handleClickOnRegion(region)}
          >
            <img
              src={region.thumbnailUrl}
              alt={region.name}
              style={{
                width: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
            <div
              style={{
                position: 'absolute',
                top: '2rem',
                marginLeft: '1.5rem',
                fontFamily: 'Ms Madi',
                fontWeight: 700,
                fontSize: '4rem',
                transform: 'rotate(-10deg)',
              }}
            >
              {region.name}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '1rem',
              }}
            >
              <BookNowBtn onClick={() => handleClickOnRegion(region)} />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
