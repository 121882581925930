import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './Navbar.css';
import { useTranslation } from 'react-i18next';
import { GoBackBTn } from 'components/Buttons/GoBackBtn';
import { useToursStore } from 'store/tourStore';

const NavBar: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const regions = useToursStore().regions;

  const isHomePage = location.pathname === '/';
  const isMobile = window.innerWidth < 768;
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const getRegionNameFromUrl = (): string | undefined => {
    const match = location.pathname.match(/^\/([^/]+)/);
    return match ? decodeURIComponent(match[1]).toLocaleLowerCase() : undefined;
  };

  const selectedRegion = regions.find(
    (r) => r.name.toLocaleLowerCase() === getRegionNameFromUrl(),
  );
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleGoBack = () => {
    if (window.history.state && window.history.state.idx > 0) {
      navigate(-1);
    } else {
      navigate('/#ourTours');
    }
  };

  return (
    <div className='nav-bar'>
      {!isHomePage && (
        <div className='goBack' onClick={handleGoBack}>
          <GoBackBTn
            backgroundImageUrl={selectedRegion?.backButtonUrl}
            text={selectedRegion?.name}
          />
        </div>
      )}
      {(!isMobile || isHomePage) && (
        <div className='logo'>
          <img src='/assets/logo.png' alt='Logo' className='logo-image' />
        </div>
      )}

      <div className='hamburger-menu' onClick={toggleMenu}>
        <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
        <div className={`bar ${isMenuOpen ? 'open' : ''}`}></div>
      </div>

      {/* Menu */}
      <div className={`menu ${isMenuOpen ? 'active' : ''}`}>
        <Link to='/' className='menu-item' onClick={toggleMenu}>
          {t('menu.home').toLocaleUpperCase()}
        </Link>
        <Link to='/#ourTours' className='menu-item' onClick={toggleMenu}>
          {t('menu.ourTours').toLocaleUpperCase()}
        </Link>
        <Link to='/#about' className='menu-item' onClick={toggleMenu}>
          {t('menu.about').toLocaleUpperCase()}
        </Link>
        <Link to='/#contact' className='menu-item' onClick={toggleMenu}>
          {t('menu.contact').toLocaleUpperCase()}
        </Link>
      </div>
    </div>
  );
};

export default NavBar;
