import React, { ReactNode } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface CustomArrowProps {
  onClick?: () => void;
}

interface CastleSliderProps {
  settings?: {
    dots?: boolean;
    showArrows?: boolean;
    infinite?: boolean;
    speed?: number;
    slidesToShow?: number;
    slidesToScroll?: number;
    autoplay?: boolean;
    autoplaySpeed?: number;
    afterChange?: (index: number) => void;
  };
  children?: ReactNode;
  onSlideChange?: (index: number) => void;
}

const CustomPrevArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <div
    style={{
      position: 'absolute',
      top: '70%',
      left: '1rem',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 9,
    }}
    onClick={onClick}
  >
    <img
      src='./assets/icons/back_arrow.png'
      alt='Previous'
      style={{ width: '3rem', height: '3rem', objectFit: 'contain' }}
    />
  </div>
);

const CustomNextArrow: React.FC<CustomArrowProps> = ({ onClick }) => (
  <div
    style={{
      position: 'absolute',
      top: '70%',
      right: '1rem',
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      zIndex: 9,
    }}
    onClick={onClick}
  >
    <img
      src='./assets/icons/front_arrow.png'
      alt='Next'
      style={{ width: '3rem', height: '3rem', objectFit: 'contain' }}
    />
  </div>
);

export const CastleSlider: React.FC<CastleSliderProps> = ({
  settings,
  children,
  onSlideChange,
}) => {
  const isMobile = window.innerWidth < 768;

  const defaultSettings = {
    dots: true,
    infinite: true,
    showArrows: isMobile,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    afterChange: (index: number) => {
      if (onSlideChange) {
        onSlideChange(index);
      }
    },
  };

  const mergedSettings = { ...defaultSettings, ...settings };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Slider {...mergedSettings}>{children}</Slider>
    </div>
  );
};
