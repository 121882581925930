/* eslint-disable react-hooks/exhaustive-deps */

import React, { ReactElement, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Region, Tour, useToursStore } from 'store/tourStore';
import BookNowBtn from 'components/Buttons/BookNowBtn';
import { TourMngt } from 'hooks/TourMngt.hook';

export const RegionScreen: React.FC = (): ReactElement => {
  const { regionName } = useParams();
  const { regions } = useToursStore();

  const { setSelectedTour, handleTourClick } = TourMngt();

  const [selectedRegion, setSelectedRegion] = useState<Region | undefined>();

  const navigate = useNavigate();

  const handleOnClick = (tour: Tour) => {
    setSelectedTour(tour);
    handleTourClick(tour);
  };

  useEffect(() => {
    const region = regions.find(
      (r) => r.name.toLocaleLowerCase() === regionName?.toLocaleLowerCase(),
    );
    region !== undefined ? setSelectedRegion(region) : navigate(`/`);
  }, [regions, regionName]);

  if (!regions || !selectedRegion) return <div>Loading...</div>;

  return (
    <div
      className='region-screen-container'
      style={{
        padding: '5vh',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
      }}
    >
      {selectedRegion.tours.map((tour) => (
        <div
          key={tour.name}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: '5vh',
            borderRadius: '1vw',
          }}
        >
          <img
            src={tour.mainPicture}
            alt={tour.name}
            onClick={() => handleOnClick(tour)}
            style={{
              width: '75%',
            }}
          />
          <h3
            style={{
              fontFamily: 'frank-ruhl-libre',
              fontSize: '1.9rem',
              textAlign: 'center',
            }}
          >
            {tour.name.toLocaleUpperCase()}
          </h3>
          <BookNowBtn onClick={() => handleOnClick(tour)} />
        </div>
      ))}
    </div>
  );
};
