import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export const TermsAndConditions: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  useEffect(() => { window.scrollTo(0, 0);}, []);
  return (
    <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <h1>{t('termsAndConditions.title')}</h1>
      <p>{t('termsAndConditions.subtitle')}</p>

      <h2>{t('termsAndConditions.cancellationPolicy.title')}</h2>
      <p>{t('termsAndConditions.cancellationPolicy.description')}</p>

      <h2>{t('termsAndConditions.bookingAdjustments.title')}</h2>
      <p>{t('termsAndConditions.bookingAdjustments.description')}</p>

      <h2>{t('termsAndConditions.liability.title')}</h2>
      <p>{t('termsAndConditions.liability.description')}</p>

      <h2>{t('termsAndConditions.privateTours.title')}</h2>
      <p>{t('termsAndConditions.privateTours.description')}</p>

      <h2>{t('termsAndConditions.discounts.title')}</h2>
      <p>{t('termsAndConditions.discounts.description')}</p>

      <h2>{t('termsAndConditions.lastMinuteBookings.title')}</h2>
      <p>{t('termsAndConditions.lastMinuteBookings.description')}</p>

      <h2>{t('termsAndConditions.contact.title')}</h2>
      <p>
        {t('termsAndConditions.contact.address')}: Rua da Fábrica da Sola 1A R/C Direito, 2300-123 Tomar, Portugal
      </p>
      <p>{t('termsAndConditions.contact.email')}: geral@openroadsportugal.com</p>
      <p>{t('termsAndConditions.contact.phone')}: +351 915 875 412</p>

      <p>{t('termsAndConditions.footer')}</p>
    </div>
  );
};
