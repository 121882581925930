import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import './AboutUs.css'; 

export const AboutUs: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <h2
        style={{
          fontSize: '3.5rem',
          textAlign: 'left',
          paddingLeft: '5%',
          fontFamily: 'Lexend',
          fontWeight: 700,
          marginBottom: '20px',
        }}
      >
        {t('menu.about').toUpperCase()}
      </h2>

      <div className='about-us-container'>
        <div className='about-us-image-container'>
          <img src='./assets/images/aboutUs/aboutUs.png' alt='About Us' />
          <img
            src='./assets/slogan/about_us.png'
            alt='About Us'
            style={{
              position: 'relative',
              top: '-50px',
                zIndex: 2,
            }}
          />
        </div>

        <div className='about-us-text-container'>
             <div dangerouslySetInnerHTML={{ __html: t('aboutUsDescription') }} />
        </div>
      </div>
    </>
  );
};
