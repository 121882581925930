import React from 'react';
import { useTranslation } from 'react-i18next';

type ButtonProps = {
  isInverted?: boolean;
  isStiky?: boolean;
  onClick?: () => void;
};

const BookNowBtn: React.FC<ButtonProps> = ({
  isInverted = false,
  isStiky = false,
  onClick,
}) => {
  const { t } = useTranslation();
  const baseButtonStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    fontFamily: 'Lexend, sans-serif',
    fontWeight: 700,
    cursor: 'pointer',
    fontSize: '1.5rem',
    gap: '10px',
    border: 'none',
    borderRadius: '5px',
  };

  const getButtonStyle = (
    isInverted: boolean,
    isSticky: boolean,
  ): React.CSSProperties => ({
    ...baseButtonStyle,
    color: isInverted ? '#003D6F' : '#ffffff',
    backgroundColor: isInverted ? '#ffffff' : '#003D6F',
    ...(isSticky && {
      position: 'fixed',
      bottom: '10%',
      right: '5%',
      zIndex: 100,
    }),
  });

  const icon = !isInverted
    ? '/assets/icons/front_arrow.png'
    : '/assets/icons/blue_front_arrow.png';

  const iconStyle: React.CSSProperties = {
    width: '32px',
    height: '16px',
  };

  return (
    <button style={getButtonStyle(isInverted, isStiky)} onClick={onClick}>
      {t('bookNow').toUpperCase()}
      {icon && <img src={icon} alt='icon' style={iconStyle} />}
    </button>
  );
};

export default BookNowBtn;
