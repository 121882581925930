import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FaInstagram,
  FaFacebook,
  FaEnvelope,
  FaPhoneAlt,
} from 'react-icons/fa';

export const ContactUs: React.FC = (): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <h2
        style={{
          fontSize: '3.5rem',
          textAlign: 'left',
          paddingLeft: '5%',
          fontFamily: 'Lexend',
          fontWeight: 700,
          marginBottom: '30px',
        }}
      >
        {t('menu.contact').toUpperCase()}
      </h2>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
        }}
      >
        <img
          src='./assets/slogan/your_local_guide.png'
          alt='Contact Us'
          style={{
            maxWidth: '100%',
            height: 'auto',
            objectFit: 'contain',
            marginBottom: '30px',
          }}
        />

        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            marginBottom: '30px',
          }}
        >
          <a
            href='https://www.instagram.com/open_roads_portugal'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              margin: '10px',
              fontSize: '2.5rem',
              color: '#fff', // White color for Instagram icon
            }}
          >
            <FaInstagram />
          </a>

          <a
            href='https://www.facebook.com/openroadsportugal'
            target='_blank'
            rel='noopener noreferrer'
            style={{
              margin: '10px',
              fontSize: '2.5rem',
              color: '#fff', // White color for Facebook icon
            }}
          >
            <FaFacebook />
          </a>

          <a
            href='mailto:geral@openroadsportugal.com'
            style={{
              margin: '10px',
              fontSize: '2.5rem',
              color: '#fff', // White color for Email icon
            }}
          >
            <FaEnvelope />
          </a>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <a
            href='tel:+351915875412'
            style={{
              margin: '10px 0',
              fontSize: '1.8rem',
              color: '#fff', // White color for Phone icon and text
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FaPhoneAlt style={{ marginRight: '8px' }} />
            (+351) 915 875 412
          </a>
          <a
            href='tel:+351910632563'
            style={{
              margin: '10px 0',
              fontSize: '1.8rem',
              color: '#fff', // White color for Phone icon and text
              textDecoration: 'none',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FaPhoneAlt style={{ marginRight: '8px' }} />
            (+351) 910 632 563
          </a>
        </div>
      </div>
    </>
  );
};
