import { create } from 'zustand';

export type Language = 'en' | 'pt';

export interface Tour {
  code: string;
  name: string;
  bookNowURL: string;
  description?: string;
  highlights?: string[];
  duration?: string;
  numberOfPeople?: number;
  featuredTourUrl?: string;
  mainPicture?: string;
  displayPictures?: string[];
}

export interface Region {
  code: string;
  name: string;
  thumbnailUrl?: string;
  backButtonUrl?: string;
  tours: Tour[];
}

interface ToursStore {
  regions: Region[];
  language: Language;
  setRegions: (regions: Region[]) => void;
  setLanguage: (language: Language) => void;
}

const cachedRegions: Region[] = (() => {
  const regions = localStorage.getItem('regions');
  if (regions) {
    try {
      const parsedRegions = JSON.parse(regions);
      return Array.isArray(parsedRegions) ? parsedRegions : [];
    } catch (e) {
      console.error('Error parsing regions from localStorage', e);
      return [];
    }
  }
  return [];
})();

export const useToursStore = create<ToursStore>((set) => ({
  regions: cachedRegions,
  language: 'en',
  setRegions: (regions: Region[]) => {
    set({ regions: regions });
  },
  setLanguage: (language: Language) => set({ language: language }),
}));
