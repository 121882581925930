import { ReactElement, useEffect } from 'react';
import { FeaturedTour } from './home/FeaturedTour';
import { ContactUs } from './home/ContactUs';
import { useLocation } from 'react-router-dom';
import { AboutUs } from './home/AboutUs';
import { Tours } from './home/Tours';

export const HomeScreen: React.FC = (): ReactElement => {
  const location = useLocation();

  useEffect(() => {
    const targetId = location.hash.replace('#', '');
    const target = document.getElementById(targetId);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div>
      <FeaturedTour />
      <div id='ourTours'>
        <Tours />{' '}
      </div>
      <div id='about'>
        <AboutUs />{' '}
      </div>
      <div id='contact'>
        <ContactUs />{' '}
      </div>
    </div>
  );
};
