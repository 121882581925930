import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tour } from 'store/tourStore';

export const TourMngt = () => {
  const [selectedTour, setSelectedTour] = useState<Tour>();
  const navigate = useNavigate();

  const handleTourClick = (tour: Tour) => {
    setSelectedTour(tour);
    navigate(`${tour.name}`);
  };

  return {
    selectedTour,
    setSelectedTour,
    handleTourClick,
  };
};
