import BookNowBtn from 'components/Buttons/BookNowBtn';
import { CastleSlider } from 'components/Sliders/CastleSlider';
import { TourMngt } from 'hooks/TourMngt.hook';
import React, { ReactElement } from 'react';
import { useToursStore } from 'store/tourStore';

export const FeaturedTour: React.FC = (): ReactElement => {
  const { regions } = useToursStore();
  const { selectedTour, setSelectedTour } = TourMngt();
  const flattenTours = regions.flatMap((region) => region.tours);
  const isMobile = window.innerWidth < 768;
  const handleSliderChange = (index: number) => {
    const tour = flattenTours[index];
    if (tour) {
      setSelectedTour(flattenTours[index]);
    }
  };

  const handleOnClick = () => {
    selectedTour ? (window.location.href = selectedTour.bookNowURL) : null;
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          padding: '20px',
          flex: 1,
          position: 'relative',
          maxWidth: '600px',
          minWidth: '300px',
          margin: '0 auto',
        }}
      >
        <CastleSlider onSlideChange={handleSliderChange}>
          {Array.isArray(flattenTours) && flattenTours.length > 0 ? (
            flattenTours.map((tour, index) => (
              <div
                key={index}
                style={{
                  width: '100%',
                  position: 'relative',
                }}
              >
                <img
                  src={tour.featuredTourUrl}
                  alt={tour.name}
                  style={{
                    zIndex: 1,
                    width: '100%',
                    height: 'auto',
                  }}
                />
              </div>
            ))
          ) : (
            <div>No tours available.</div>
          )}
        </CastleSlider>
      </div>

      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '0 auto',
          padding: '20px',
          boxSizing: 'border-box',
        }}
      >
        {isMobile && (
          <div
            style={{
              width: '100%',
              textAlign: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <BookNowBtn isInverted onClick={handleOnClick} />
          </div>
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Stack images vertically on mobile
            justifyContent: 'center',
            alignItems: 'center',
            gap: isMobile ? '10px' : '20px', // Adjust spacing between images
          }}
        >
          <img
            src='./assets/slogan/your_private_journey.png'
            alt='Your Private Journey, Guided by Locals'
            style={{
              maxWidth: isMobile ? '80%' : '50%', // Adjust size for mobile
              height: 'auto', // Maintain aspect ratio
            }}
          />
          {!isMobile && (
            <img
              src='./assets/slogan/since2019.png'
              alt='Since 2019'
              style={{
                maxWidth: '100%',
                maxHeight: '75%',
              }}
            />
          )}
        </div>

        {!isMobile && (
          <div
            style={{ width: '100%', textAlign: 'center', marginLeft: '100%' }}
          >
            <BookNowBtn isInverted onClick={handleOnClick} />
          </div>
        )}
      </div>
    </div>
  );
};
