import axios from 'axios';
import { Region, useToursStore } from 'store/tourStore';

export const fetchRegions = async (): Promise<void> => {
  try {
    const { language } = useToursStore.getState();
    const tourStore = useToursStore.getState();
    const url: string | undefined =
      language === 'pt'
        ? process.env.REACT_APP_OPEN_ROADS_API_PT_URL
        : process.env.REACT_APP_OPEN_ROADS_API_EN_URL;

    if (!url) {
      console.error(
        'Error: Environment variable for the regions URL is not set.',
      );
      return;
    }
    const response = await axios.get(url);
    if (response.status === 200 && response.data) {
      if (!response.data.regions || !Array.isArray(response.data.regions)) {
        return;
      }
      const regions: Region[] = response.data.regions;
      tourStore.setRegions(regions);
      localStorage.setItem('regions', JSON.stringify(regions));
    } else {
      console.error('Failed to fetch regions: Invalid response');
    }
  } catch (error) {
    console.error('Error fetching regions:', error);
  }
};
