import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useToursStore } from 'store/tourStore';
import BookNowBtn from 'components/Buttons/BookNowBtn';
import { TourSlider } from 'components/Sliders/TourSlider';

export const TourScreen: React.FC = (): ReactElement => {
  const { tourName } = useParams();
  const { regions } = useToursStore();
  const { t } = useTranslation();

  const tour = regions
    .flatMap((region) => region.tours)
    .find((t) => t.name.toLowerCase() === tourName?.toLowerCase());

  if (!tour) return <div>Loading...</div>;

  return (
    <div
      style={{
        padding: '16px',
        fontFamily: '"Lexend", sans-serif',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      {/* Header */}
      <div style={{ textAlign: 'center', marginBottom: '24px' }}>
        <h1
          style={{
            fontSize: '2.5rem',
            fontWeight: '700',
            margin: '0',
            wordWrap: 'break-word',
          }}
        >
          {tour.name}
        </h1>
      </div>

      {/* Info Section */}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
          marginBottom: '24px',
          gap: '16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '1rem',
          }}
        >
          <img
            src='/assets/icons/clock.png'
            alt='Duration'
            style={{ width: '24px', height: '24px' }}
          />
          <span>{tour.duration}</span>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '1rem',
          }}
        >
          <img
            src='/assets/icons/check.png'
            alt='Certified Guide'
            style={{ width: '24px', height: '24px' }}
          />
          <span>{t('localGuideCertified')}</span>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontSize: '1rem',
          }}
        >
          <img
            src='/assets/icons/people.png'
            alt='Max People'
            style={{ width: '24px', height: '24px' }}
          />
          <span>{t('maxPeople', { x: tour.numberOfPeople })}</span>
        </div>
      </div>

      <div
        style={{
          fontSize: '1rem',
          lineHeight: '1.6',
          marginBottom: '24px',
          textAlign: 'justify',
        }}
      >
        <p>{tour.description}</p>
        {tour.highlights && (
          <ul style={{ marginTop: '16px', paddingLeft: '20px' }}>
            {tour.highlights.map((highlight, index) => (
              <li key={index} style={{ marginBottom: '8px' }}>
                {highlight}
              </li>
            ))}
          </ul>
        )}
      </div>

      {tour.displayPictures && <TourSlider images={tour.displayPictures} />}

      <div>
        <BookNowBtn
          isStiky
          onClick={() =>
            tour.bookNowURL ? (window.location.href = tour.bookNowURL) : null
          }
        />
      </div>
    </div>
  );
};
