import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const Footer: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <footer
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#003D6F',
        padding: '1rem 2rem',
        borderRadius: '12px 12px 0 0',
        boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
        fontFamily: 'frank-ruhl-libre',
      }}
    >
      <div>
        <img
          src='/assets/logo.png'
          alt='Logo'
          style={{ height: '50%', width: '50%' }}
        />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <p
          style={{
            margin: 0,
            fontSize: '1rem',
            color: 'white',
          }}
        >
          {t('allRights', { year: new Date().getFullYear() })}
        </p>
       
        <span
          style={{
            margin: '0 0 0 1rem',
            fontSize: '0.9rem',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={() => navigate('/termsAndConditions')}
        >
       
          {t('termsAndConditions.title')}
        </span>
      </div>
    </footer>
  );
};
