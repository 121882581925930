import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface TourSliderProps {
  images: string[];
}
export const TourSlider: React.FC<TourSliderProps> = ({ images }) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    adaptiveHeight: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <div
      style={{
        margin: '20px auto', // Center the slider
        maxWidth: '1200px', // Constrain the width for large screens
        width: '90%', // Add padding for smaller screens
        position: 'relative',
      }}
    >
      <Slider {...sliderSettings}>
        {images.map((image, index) => (
          <div key={index} style={{ padding: '10px' }}>
            <img
              src={image}
              alt={`Tour Image ${index + 1}`}
              style={{
                width: '100%',
                height: 'auto',
                borderRadius: '8px',
              }}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default TourSlider;
