import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Region } from 'store/tourStore';

export const RegionMngt = () => {
  const [selectedRegion, setSelectedRegion] = useState<Region>();
  const navigate = useNavigate();

  const handleRegionClick = (region: Region) => {
    setSelectedRegion(region);
    region.tours.length === 1
      ? navigate(`/${region.name}/${region.tours[0].name}`)
      : navigate(`/${region.name}`);
  };

  return {
    selectedRegion,
    setSelectedRegion,
    handleRegionClick,
  };
};
