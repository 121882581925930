/* eslint-disable react-hooks/exhaustive-deps */

import  { useEffect } from 'react';
import { Route, Routes, HashRouter } from 'react-router-dom';
import { useToursStore } from 'store/tourStore';
import { HomeScreen } from 'screens/HomeScreen';
import { RegionScreen } from 'screens/RegionScreen';
import { Footer } from 'screens/Footer/Footer';
import { TourScreen } from 'screens/TourScreen';
import { fetchRegions } from 'services/fetchRegions';
import i18n from 'i18n';
import NavBar from 'Navbar';
import { TermsAndConditions } from 'screens/TermsAndConditions';
function App() {
  const { language, setLanguage } = useToursStore();

  useEffect(() => {
    i18n.changeLanguage(language);
    fetchRegions();
  }, [language]);

  useEffect(() => {
    const browserLanguage = navigator.language.split('-')[0] as string;

    if (browserLanguage.toLocaleLowerCase().includes('pt')) {
      setLanguage('pt');
    } else {
      setLanguage('en');
    }
  }, []);
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#114c81',
        color: '#ffffff',
      }}
    >
      <HashRouter>
        <NavBar />
        <div style={{ flex: 1 }}>
          <Routes>
            <Route path='/' element={<HomeScreen />} />
            <Route path='/:regionName' element={<RegionScreen />} />
            <Route path='/:regionName/:tourName' element={<TourScreen />} />
            <Route path='/termsAndConditions' element={<TermsAndConditions />} />
          </Routes>
        </div>
        <Footer />
      </HashRouter>
    </div>
  );
}

export default App;
