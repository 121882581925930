import React from 'react';

type ButtonProps = {
  backgroundImageUrl?: string;
  text?: string;
};
export const GoBackBTn: React.FC<ButtonProps> = ({
  backgroundImageUrl,
  text,
}) => {
  const buttonStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${backgroundImageUrl})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'inherit',
    fontFamily: 'Ms Madi',
    fontSize: '2rem',
    fontWeight: '700',
    color: 'white',
    borderRadius: '20px',
    borderColor: 'transparent',
  };

  const iconStyle: React.CSSProperties = {
    width: '32px',
    height: '16px',
  };

  return (
    <button style={buttonStyle}>
      <img
        src={'/assets/icons/back_arrow.png'}
        alt='go back'
        style={iconStyle}
      />
      <div
        style={{
          transform: 'rotate(-10deg) translateY(50%) translateX(30%)',
        }}
      >
        {text}
      </div>
    </button>
  );
};
